// assets
import { IconHome, IconDashboard } from '@tabler/icons';

// constant
const icons = { IconHome, IconDashboard };

// ==============================|| home MENU ITEMS ||============================== //

const home = {
  id: 'home',
  title: '',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Início',
      type: 'item',
      url: '/',
      icon: icons.IconHome,
      breadcrumbs: false
    },
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default home;
